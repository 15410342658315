// store/index.js

import { createStore } from 'vuex';
import { router } from '../router.js'
import axios from "@/util/axios";
import i18n from '../locales/index.js';
import Toast from '../components/Toast/toast.js';
import ws from './modules/ws'

// 获取invitationCode
if (getUrlParam('invitationCode') || localStorage["invitationCode"]) {
    localStorage["invitationCode"] = getUrlParam('invitationCode') || localStorage["invitationCode"];
    if (eventBur) {
        eventBur.onInitialize(localStorage["invitationCode"]);
    }
}

// 设备类型
const userAgent = navigator.userAgent.toLowerCase();
// 判断是否是安卓
const isAndroid = /android/.test(userAgent);
// 判断是否是 iOS
const isIOS = /(iphone|ipad|ipod|ios)/.test(userAgent);
let deviceType = '0';//0 pc 1 android 2 ios 3 android_app 4 ios_app
if (getUrlParam('device')) {
    deviceType = getUrlParam('device');
} else if (isIOS) {
    deviceType = '2';
} else if (isAndroid) {
    deviceType = '1';
}
localStorage["deviceType"] = deviceType;

const state = {
    hotLotteryInfos: [],// 首页热门彩票
    lotteryData: {},// 当前彩种对象
    rotateOpen: false,
    serverTimeObj: null,
    serverTime: 0,
    closeTime: 0,
    openTime: 0,
    userNoticeMarquee: [],
    userNoticeWindow: {},
    userInfo: {},
    pingObj: null, // ping定时器

}
const state2 = {
    activeList: localStorage['activeList'] ? JSON.parse(localStorage['activeList']) : [],
    queryGameOpen: false,
    noticeShow: true,
    walletOpen: true,
    zpPopupOpen: true,
    totalCommissionOpen: true,
    isPWA: true,
    winningList: [],
    winRank: [],
    bannerList: [],
    loadShow: false,
    loadButShow: false,
    deviceType: deviceType,
    money: 0,
    profile: 0,
    safeBoxMoney: 0,
    gameTypeList: [
        { type: 0, text: "彩票", key: 'lotteryRatio' },
        { type: 3, text: "老虎机", key: 'slotsRatio' },
        { type: 2, text: "棋牌", key: 'pocketRatio' },
        { type: 1, text: "捕鱼", key: 'fishingRatio' },

        { type: 4, text: "Live Casino", key: 'liveRatio' },
        { type: 5, text: "Mini games", key: 'miniGamesRatio' },
        { type: 6, text: "Sports", key: 'sportRatio' },
    ],
    threeGameList: new Map([
        [3, { gameType: 3, gameList: [] }],
        [2, { gameType: 2, gameList: [] }],
        [1, { gameType: 1, gameList: [] }],
        [4, { gameType: 4, gameList: [] }],
        [5, { gameType: 5, gameList: [] }],
        [6, { gameType: 6, gameList: [] }]
    ]),
    // 控制im弹窗
    chatPageShow: false,
    chatPageOpen: false,
    gameTypeData: {
        type: 0,
        text: "彩票",
    },
}
const store = createStore({
    state() {
        return sessionStorage.getItem('state') ? Object.assign(JSON.parse(sessionStorage.getItem('state')), state2) : Object.assign(state, state2);
    },
    modules: {
        ws,
    },
    mutations: {
        SET_gameTypeData(state, data) {
            state.gameTypeData = data;
        },
        SET_chatPage(state, bool) {
            if (bool) {
                state.loadButShow = true;
            } else {
                state.loadButShow = false;
            }
            state.chatPageShow = bool;
            state.chatPageOpen = bool;
        },
        // 活动列表
        SET_activeList(state, list) {
            state.activeList = list;
            localStorage['activeList'] = JSON.stringify(list);
        },
        // 首页游戏搜索弹窗
        SET_queryGameOpen(state, bool) {
            state.queryGameOpen = bool;
        },
        // 中奖排行榜
        SET_winningList(state, data) {
            state.winningList = data || [];
        },
        // 盈利排行榜
        SET_winRank(state, data) {
            state.winRank = data || [];
        },
        // 轮播广告图
        SET_bannerList(state, data) {
            state.bannerList = data.sort((a, b) => a.bannerSort - b.bannerSort);
        },
        SET_userInfo(state, data) {
            state.userInfo = data;
        },
        // 跑马灯公告
        SET_userNoticeMarquee(state, data) {
            state.userNoticeMarquee = data || [];
        },
        // 弹窗公告
        SET_userNoticeWindow(state, data) {
            state.userNoticeWindow = data || {};
        },
        SET_noticeShow(state, bool) {
            state.noticeShow = bool;
        },
        SET_money(state, data) {
            state.money = data || '0.00';
        },
        SET_profile(state, data) {
            state.profile = data;
        },
        SET_safeBoxMoney(state, data) {
            state.safeBoxMoney = data;
        },
        SET_threeGameList(state, data) {
            state.threeGameList = data;
        },
        SET_hotLotteryInfos(state, data) {
            state.hotLotteryInfos = data;
        },
        SET_lotteryData(state, data) {
            state.lotteryData = data;
        },
        SET_serverTime(state, time) {
            const date1 = +new Date(time.replace(/-/g, "/"));
            state.serverTime = date1;
            clearInterval(state.serverTimeObj);
            state.serverTimeObj = setInterval(() => {
                state.serverTime += 1000;
            }, 1000);
        },
        SET_closeTime(state, time) {
            const date1 = +new Date(time.replace(/-/g, "/"));
            state.closeTime = date1;
        },
        SET_openTime(state, time) {
            const date1 = +new Date(time.replace(/-/g, "/"));
            state.openTime = date1;
        },
    },
    actions: {
        toPath({ state, commit, dispatch }, data) {
            if (data) {
                if (data === 'null') {
                    Toast(i18n.global.t('暂未开放'));
                } else {
                    router.push(data);
                }
            } else {
                router.go(-1)
            }
        },
        // 用户信息
        getUserInfo({ state, commit, dispatch }) {
            axios.post('/info/index').then((res) => {
                commit('SET_userInfo', res.data);
            })
        },
        // 刷新金额
        getMoney({ state, commit, dispatch }) {
            state.rotateOpen = true;
            axios.post('/info/money').then((res) => {
                commit('SET_profile', res.data.profile);
                commit('SET_safeBoxMoney', res.data.safeBoxMoney);
                commit('SET_money', res.data.accountMoney);
            }).finally(() => {
                state.rotateOpen = false;
            })
        },
        // 跑马灯公告
        getUserNoticeMarquee({ state, commit }) {
            axios.post('/info/userNoticeMarquee').then((res) => {
                commit('SET_userNoticeMarquee', res.data);
            })
        },
        // 弹窗公告
        getUserNoticeWindow({ state, commit }) {
            // 弹窗公告
            axios.post('/info/userNoticeWindow').then((res) => {
                commit('SET_userNoticeWindow', res.data);
            })
        },
        // 初始化数据
        getInitializeData({ commit, dispatch }) {
            // 弹窗公告
            dispatch('getUserNoticeWindow');
            // 获取ip
            axios.post('/info/cacheIp').then(() => { })
            // 中奖排行榜
            axios.post('/lottery/winning').then((res) => {
                commit('SET_winningList', res.data);
            })
            // 盈利排行榜
            axios.post('/lottery/winRank').then((res) => {
                commit('SET_winRank', res.data);
            })
            // 轮播广告图
            axios.post('/info/bannerList').then((res) => {
                commit('SET_bannerList', res.data || []);
            })
        },
        // 定时请求接口
        ping({ state, dispatch }) {
            clearTimeout(state.pingObj);
            state.pingObj = setTimeout(() => {
                dispatch('ping')
            }, 10000);
            if (!sessionStorage["sessionId"]) {
                return;
            }
            if (eventBur && fbOpen) {
                axios.post('/info/historyDepositReport').then((res) => {
                    let data = res.data || {};
                    // 首充上报
                    if (data.historyFirstDepositReport === 0) {
                        eventBur.oncz(data.historyFirstDepositAmount);
                    }
                    // 次充上报
                    if (data.historyTwoDepositReport === 0) {
                        eventBur.oncc(data.historyTwoDepositAmount);
                    }
                })
            }
        },
        // 更新项目版本
        isNewVersion() {
            let url = `${window.location.origin}/version.json?t=${new Date().getTime()}`;
            axios.get2(url
            ).then(res => {
                if (res.version) {
                    let vueVersion = res.version,
                        localVueVersion = localStorage.getItem('vueVersion');
                    if (localVueVersion && localVueVersion != vueVersion) {
                        // 当前版本有更新，点击确认立即体验
                        if ('serviceWorker' in navigator) {
                            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                                for (let registration of registrations) {
                                    registration.unregister().then(function () {
                                        console.log('Service Worker unregistered successfully');
                                        setTimeout(() => {
                                            localStorage.setItem('vueVersion', vueVersion);
                                            window.location.reload();
                                        }, 3000);
                                    }).catch(function (error) {
                                        console.error('Failed to unregister Service Worker:', error);
                                    });
                                }
                            });
                        } else {
                            localStorage.setItem('vueVersion', vueVersion);
                            window.location.reload();
                        }
                        return;
                    }
                }
            })
        }
    },
    getters: {
        // 开奖时间倒计时
        getOpenTime(state) {
            let time = (state.openTime - state.serverTime) > 0 ? state.openTime - state.serverTime : 0;
            const diffInSeconds = Math.abs(time) / 1000;
            return diffInSeconds;
        },
        // 封盘时间倒计时
        getCloseTime(state) {
            let time = (state.closeTime - state.serverTime) > 0 ? state.closeTime - state.serverTime : 0;
            const diffInSeconds = Math.abs(time) / 1000;
            return diffInSeconds;
        },
    }
});
// 获取URL参数
function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) return unescape(r[2]);
    return null; //返回参数值
}

export default store;
